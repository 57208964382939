import { FC, memo } from "react";
import { FcHighPriority } from "react-icons/fc";
import Modal from "react-modal";
import { useRecoilState } from "recoil";
import { registModalInfoState } from "../../../store/modal/registModalInfoState";
import { PrimaryButton } from "../../atoms/PrimaryButton";
import { SecondaryButton } from "../../atoms/SecondaryButton";
import { BookControlForm } from "../../../types/form/BookControlForm";
import { DuplicateBookInfo } from "../../../types/api/DuplicateBookInfo";

type Props = {
  returnTarget: BookControlForm;
  duplicateBook: DuplicateBookInfo;
  onClickRegist: () => void;
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.4)",
  },
};

export const BookRegistConfirmModal: FC<Props> = memo((props) => {
  const { returnTarget, duplicateBook, onClickRegist } = props;
  const [modalInfo, setModalInfo] = useRecoilState(registModalInfoState);

  const closeModal = () => {
    setModalInfo({ isOpen: false });
  };

  // キャンセルボタン押下
  const cancel = () => {
    setModalInfo({ isOpen: false });
  };

  return (
    <>
      <Modal isOpen={modalInfo.isOpen} onRequestClose={closeModal} style={customStyles}>
        <div className="font-body">
          <div className="text-4xl flex items-center justify-center mb-3">
            <FcHighPriority />
          </div>
          以下書籍は現在{duplicateBook.duplicateBookNum}冊登録されています。本当に登録してもよろしいですか？
          <div className="pt-3 flex">
            <div className="text-sm">
              バーコード番号：{returnTarget.isbnCode}
              <br />
              書籍名：{returnTarget.bookName}
            </div>
          </div>
          <div className="flex flex-row justify-end items-center pt-4">
            <div className="pr-2">
              <PrimaryButton onClick={onClickRegist}>OK</PrimaryButton>
            </div>
            <div className="pr-2">
              <SecondaryButton onClick={cancel}>キャンセル</SecondaryButton>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});
