import { FC, memo, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const StatusIcon: FC<Props> = memo((props) => {
  const { children } = props;

  return (
    <>
      <span className="px-2 ml-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
        {children}
      </span>
    </>
  );
});
