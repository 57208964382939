import { FC, memo, useEffect } from "react";
import Modal from "react-modal";
import { useRecoilState, useRecoilValue } from "recoil";
import { useFetchLendHistory } from "../../../hooks/useFetchLendHistory";
import { lendHistoryModalInfoState } from "../../../store/modal/lendHistoryModalInfoState";
import { usersState } from "../../../store/usersState";
import { BookControlForm } from "../../../types/form/BookControlForm";
import { SecondaryButton } from "../../atoms/SecondaryButton";

type Props = {
  historyTarget: BookControlForm;
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "25%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.4)",
  },
};

export const BookLendHistoryModal: FC<Props> = memo((props) => {
  const { historyTarget } = props;
  const [modalInfo, setModalInfo] = useRecoilState(lendHistoryModalInfoState);
  const { fetchLendHistoryList, lendHistoryList } = useFetchLendHistory();
  const user = useRecoilValue(usersState);
  const closeModal = () => {
    setModalInfo({ isOpen: false });
  };

  useEffect(() => {
    if (
      historyTarget.id === null ||
      historyTarget.id === undefined ||
      historyTarget.id === 0
    ) {
      return;
    }
    fetchLendHistoryList(historyTarget, user.token);
  }, [historyTarget]);

  const getDisplayDate = (date = new Date()) => {
    return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}/${String(date.getDate()).padStart(2, "0")}`;
  };

  return (
    <>
      <Modal
        isOpen={modalInfo.isOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="font-body break-all">
          {lendHistoryList == null || !lendHistoryList.length ? (
            <div className="font-bold">貸出履歴はありません</div>
          ) : (
            <>
              <div className="font-bold">貸出履歴は以下の通りです</div>
              <div className="pt-3">
                <div className="text-sm">書籍名：{historyTarget.bookName}</div>
                <div className="pt-3">
                  {lendHistoryList.map((data) => {
                    return (
                      <>
                        <div className="py-1">
                          <p className="inline text-sm">
                            {`${getDisplayDate(new Date(data.lendDate))} ～
                          ${
                            data.returnDate
                              ? getDisplayDate(new Date(data.returnDate))
                              : "現在"
                          }`}
                          </p>
                          <p className="pl-2 text-sm inline">{data.employeeFullName}</p>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </>
          )}
          <div className="flex flex-row justify-end items-center pt-4">
            <div className="pr-2">
              <SecondaryButton onClick={closeModal}>閉じる</SecondaryButton>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});
